import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
  standalone: true,
})
export class PricePipe implements PipeTransform {
  transform(
    value?: number | null,
    signature?: string,
    replacement?: string,
  ): string {
    if (value === undefined || value === null) {
      return replacement || '-';
    }

    return (
      new DecimalPipe('ru-Ru').transform(value, '1.0') +
        `${signature ? signature : ''}` || '-'
    );
  }
}
