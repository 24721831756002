import { Component } from '@angular/core';

@Component({
  selector: 'app-cross-icon',
  standalone: true,
  imports: [],
  template: ` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g opacity="0.8">
      <path
        d="M18.5 6L6.5 18"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 6L18.5 18"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>`,
})
export class CrossIconComponent {}
