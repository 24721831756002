import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import {
  TAnalyticsAddress,
  TAnalyticsAnnouncement,
  TAnalyticsAnnouncementRecycles,
  TAnalyticsAnnouncementRecyclesRequest,
  TAnalyticsCereals,
  TGraph,
  TGraphPoint,
} from './priceAnalysisTypes';
import { TFormattedApiError } from '@utils/formattingApiError';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class PriceAnalyticsService {
  #announcementGraph = signal<TAnalyticsAnnouncement | null>(null);
  announcementGraph = computed(this.#announcementGraph);

  #charts = signal<TAnalyticsAnnouncementRecycles | null>(null);
  charts = computed(this.#charts);

  #addressList = signal<TAnalyticsAddress[]>([]);
  addressList = computed(this.#addressList);

  #cerealsList = signal<TAnalyticsCereals>([]);
  cerealsList = computed(this.#cerealsList);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(private http: HttpClient) {}

  loadAnalyticsAnnouncementRecycles(
    data: TAnalyticsAnnouncementRecyclesRequest,
  ): Observable<TAnalyticsAnnouncementRecycles> {
    return this.http.post<TAnalyticsAnnouncementRecycles>(
      `${environment.getApiVersionUrl('analytics/announcements-and-recyclers')}`,
      {
        ...data,
      },
    );
  }

  createAnalyticsRecyclesAnnouncementData(
    data: TAnalyticsAnnouncementRecycles,
  ) {
    const newDataAnnouncements: TGraph[] = data.announcements_graphs.map(
      (chart) => {
        const points = chart.points
          .sort((a: TGraphPoint, b: TGraphPoint) =>
            (a.period as string).localeCompare(b.period as string),
          )
          .map((item) => {
            return { ...item, period: new Date(item.period) };
          });

        return { title: chart.title, points };
      },
    );

    const newDataRecycles: TGraph[] = data.recyclers_graphs.map((chart) => {
      const points = chart.points
        .sort((a: TGraphPoint, b: TGraphPoint) =>
          (a.period as string).localeCompare(b.period as string),
        )
        .map((item) => {
          return { ...item, period: new Date(item.period) };
        });

      return { title: chart.title, points };
    });

    const newData = { ...data };
    newData.recyclers_graphs = newDataRecycles;
    newData.announcements_graphs = newDataAnnouncements;

    this.#charts.set(newData);
  }

  loadAnalyticsAnnouncement(slug: string): void {
    this.http
      .get<TAnalyticsAnnouncement>(
        `${environment.getApiVersionUrl(`analytics/announcement/${slug}`)}`,
      )
      .subscribe({
        next: (response) => this.createAnalyticsAnnouncementData(response),
        error: (error: TFormattedApiError) => {
          if (error.formattedErrorMessage)
            console.error(error.formattedErrorMessage);
        },
      });
  }

  createAnalyticsAnnouncementData(data: TAnalyticsAnnouncement) {
    const newDataAverage: TGraphPoint[] =
      data.average_announcements_graph?.points
        .sort((a: TGraphPoint, b: TGraphPoint) =>
          (a.period as string).localeCompare(b.period as string),
        )
        .map((item) => {
          return {
            ...item,
            period: new Date(item.period),
          };
        });
    const newDataAnnouncement = data.announcement_graph?.points
      .sort((a: TGraphPoint, b: TGraphPoint) =>
        (a.period as string).localeCompare(b.period as string),
      )
      .map((item) => {
        return {
          ...item,
          period: new Date(item.period),
        };
      });

    const newData = { ...data };
    if (newData.average_announcements_graph)
      newData.average_announcements_graph.points = newDataAverage;
    if (newData.announcement_graph)
      newData.announcement_graph.points = newDataAnnouncement;

    this.#announcementGraph.set(newData);
  }

  // Получение списка культур, для которых есть аналитика
  loadAnalyticsCereals(category_code: string): void {
    this.http
      .get<TAnalyticsCereals>(
        `${environment.getApiVersionUrl(`references/analytic/categories/${category_code}/cereals`)}`,
      )
      .subscribe({
        next: (response) => this.#cerealsList.set(response),
        error: (error: TFormattedApiError) => {
          this.#cerealsList.set([]);

          if (error.formattedErrorMessage)
            console.error(error.formattedErrorMessage);
        },
      });
  }

  // Получение списка регионов - городов
  loadAnalyticsAddress(): void {
    this.http
      .get<
        TAnalyticsAddress[]
      >(`${environment.getApiVersionUrl('references/analytic/address')}`)
      .subscribe({
        next: (response) => this.#addressList.set(response),
        error: (error: TFormattedApiError) => {
          this.#addressList.set([]);

          if (error.formattedErrorMessage)
            console.error(error.formattedErrorMessage);
        },
      });
  }

  resetAnnouncementGraph() {
    this.#announcementGraph.set(null);
  }

  resetAnnouncementRecyclesCharts() {
    this.#charts.set(null);
  }
}
