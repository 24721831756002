import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { INITIAL_PER_PAGE } from '@settings/constants';
import { getPaginationData } from '@utils/getPaginationHeaders';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TAnnouncementListData } from '@components/pages/home/core/announcementsListTypes';
import { TBooleanResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class OpenContactsService {
  #openContactsList = signal<TAnnouncementListData[]>([]);
  openContactsList = computed(this.#openContactsList);

  #currentPage = signal<number>(0);
  currentPage = computed(this.#currentPage);

  #totalPage = signal<number>(0);
  totalPage = computed(this.#totalPage);

  #perPage = signal<number>(0);
  perPage = computed(this.#perPage);

  #totalCount = signal<number>(0);
  totalCount = computed(this.#totalCount);

  #loader = signal<boolean>(true);
  loader = computed(this.#loader);

  #infiniteLoader = signal<boolean>(false);
  infiniteLoader = computed(this.#infiniteLoader);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(private http: HttpClient) {}

  // Список объявлений, на которых был открыт контакт
  loadOpenContactsAnnouncements({
    isInfinite,
  }: {
    isInfinite?: boolean;
  }): void {
    if (this.#currentPage() >= this.#totalPage() && this.#currentPage() !== 0)
      return;

    if (isInfinite) {
      this.#infiniteLoader.set(true);
    } else {
      this.#loader.set(true);
    }

    this.#currentPage.set(this.#currentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#currentPage(),
    };

    this.http
      .get<TAnnouncementListData[]>(
        `${environment.getApiVersionUrl('open-contacts/announcements')}`,
        {
          params: allParams,
          transferCache: {
            includeHeaders: [
              'X-Pagination-Current-Page',
              'X-Pagination-Page-Count',
              'X-Pagination-Per-Page',
              'X-Pagination-Total-Count',
            ],
          },
          observe: 'response',
        },
      )
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TAnnouncementListData[]>(response);

          this.#currentPage.set(responsePagination.currentPage);
          this.#totalPage.set(responsePagination.totalPage);
          this.#perPage.set(responsePagination.perPage);
          this.#totalCount.set(responsePagination.totalCount);

          this.#openContactsList.set([
            ...this.#openContactsList(),
            ...responsePagination.data,
          ]);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
      });
  }

  // Открытие контактов по объявлению
  openContacts(slug: string): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl(`open-contacts/announcements/${slug}`)}`,
      {},
    );
  }

  changeAnnouncementIsFavorite(slug: string) {
    this.#openContactsList().map((item) =>
      item.slug === slug ? (item.is_favorite = !item.is_favorite) : null,
    );
  }

  resetOpenContactsData() {
    this.#openContactsList.set([]);
    this.#currentPage.set(0);
    this.#totalPage.set(0);
    this.#perPage.set(0);
    this.#totalCount.set(0);
    this.#loader.set(true);
    this.#infiniteLoader.set(false);
    this.#error.set(null);
  }
}
