<div
  [ngClass]="{
    'info-block-announcement': true,
    moderation: variety() === 'moderation',
    rejected: variety() === 'rejected',
    success: variety() === 'success',
  }"
>
  <div class="title" [class.success]="variety() === 'success'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      *ngIf="variety() === 'moderation'"
    >
      <path
        d="M10 5.83333V10L12.5 12.5M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      *ngIf="variety() === 'rejected'"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.80837 2.30109C7.81941 1.8823 8.90305 1.66675 9.9974 1.66675C11.0917 1.66675 12.1754 1.8823 13.1864 2.30109C14.1975 2.71987 15.1161 3.3337 15.89 4.10752C16.6638 4.88135 17.2776 5.80001 17.6964 6.81105C18.1152 7.8221 18.3307 8.90573 18.3307 10.0001C18.3307 11.0944 18.1152 12.1781 17.6964 13.1891C17.2776 14.2002 16.6638 15.1188 15.89 15.8926C15.1161 16.6665 14.1975 17.2803 13.1864 17.6991C12.1754 18.1179 11.0917 18.3334 9.9974 18.3334C8.90305 18.3334 7.81941 18.1179 6.80837 17.6991C5.79732 17.2803 4.87866 16.6665 4.10484 15.8926C3.33102 15.1188 2.71719 14.2002 2.2984 13.1891C1.87961 12.1781 1.66406 11.0944 1.66406 10.0001C1.66406 8.90573 1.87961 7.8221 2.2984 6.81105C2.71719 5.80001 3.33102 4.88135 4.10484 4.10752C4.87866 3.3337 5.79732 2.71987 6.80837 2.30109ZM9.9974 3.33341C9.12192 3.33341 8.25501 3.50585 7.44617 3.84088C6.89509 4.06915 6.37831 4.36985 5.90912 4.73411L15.2634 14.0884C15.6276 13.6192 15.9283 13.1024 16.1566 12.5513C16.4916 11.7425 16.6641 10.8756 16.6641 10.0001C16.6641 9.1246 16.4916 8.2577 16.1566 7.44886C15.8216 6.64002 15.3305 5.90509 14.7114 5.28604C14.0924 4.66698 13.3575 4.17592 12.5486 3.84088C11.7398 3.50585 10.8729 3.33341 9.9974 3.33341ZM14.0848 15.2668L4.73071 5.91272C4.36677 6.38167 4.06632 6.89813 3.8382 7.44886C3.50317 8.2577 3.33073 9.1246 3.33073 10.0001C3.33073 10.8756 3.50317 11.7425 3.8382 12.5513C4.17323 13.3601 4.66429 14.0951 5.28335 14.7141C5.90241 15.3332 6.63734 15.8242 7.44617 16.1593C8.25501 16.4943 9.12192 16.6667 9.9974 16.6667C10.8729 16.6667 11.7398 16.4943 12.5486 16.1593C13.0993 15.9312 13.6158 15.6307 14.0848 15.2668Z"
        fill="black"
      />
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      *ngIf="variety() === 'success'"
    >
      <path
        d="M9.00391 12L11.0039 14L15.0039 10M5.00437 7.19997C5.00437 6.6165 5.23615 6.05692 5.64873 5.64434C6.06131 5.23176 6.62089 4.99997 7.20437 4.99997H8.20437C8.78527 4.99964 9.34246 4.76957 9.75437 4.35997L10.4544 3.65997C10.6588 3.45437 10.9019 3.29121 11.1696 3.17988C11.4373 3.06855 11.7244 3.01123 12.0144 3.01123C12.3043 3.01123 12.5914 3.06855 12.8591 3.17988C13.1268 3.29121 13.3699 3.45437 13.5744 3.65997L14.2744 4.35997C14.6864 4.76997 15.2444 4.99997 15.8244 4.99997H16.8244C17.4078 4.99997 17.9674 5.23176 18.38 5.64434C18.7926 6.05692 19.0244 6.6165 19.0244 7.19997V8.19997C19.0244 8.77997 19.2544 9.33797 19.6644 9.74997L20.3644 10.45C20.57 10.6544 20.7331 10.8975 20.8445 11.1652C20.9558 11.4329 21.0131 11.72 21.0131 12.01C21.0131 12.2999 20.9558 12.587 20.8445 12.8547C20.7331 13.1225 20.57 13.3655 20.3644 13.57L19.6644 14.27C19.2548 14.6819 19.0247 15.2391 19.0244 15.82V16.82C19.0244 17.4034 18.7926 17.963 18.38 18.3756C17.9674 18.7882 17.4078 19.02 16.8244 19.02H15.8244C15.2435 19.0203 14.6863 19.2504 14.2744 19.66L13.5744 20.36C13.3699 20.5656 13.1268 20.7287 12.8591 20.8401C12.5914 20.9514 12.3043 21.0087 12.0144 21.0087C11.7244 21.0087 11.4373 20.9514 11.1696 20.8401C10.9019 20.7287 10.6588 20.5656 10.4544 20.36L9.75437 19.66C9.34246 19.2504 8.78527 19.0203 8.20437 19.02H7.20437C6.62089 19.02 6.06131 18.7882 5.64873 18.3756C5.23615 17.963 5.00437 17.4034 5.00437 16.82V15.82C5.00404 15.2391 4.77397 14.6819 4.36437 14.27L3.66437 13.57C3.45877 13.3655 3.29561 13.1225 3.18427 12.8547C3.07294 12.587 3.01562 12.2999 3.01562 12.01C3.01563 11.72 3.07294 11.4329 3.18427 11.1652C3.29561 10.8975 3.45877 10.6544 3.66437 10.45L4.36437 9.74997C4.77397 9.33806 5.00404 8.78087 5.00437 8.19997V7.19997Z"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    {{ title() }}
  </div>

  <div class="description-block" *ngIf="variety() === 'rejected'">
    <div class="subtitle">Причина отклонения:</div>
    <div class="description">{{ description() }}</div>
  </div>
</div>
