export const chartColors = [
  '#6386ae',
  '#6F9305',
  '#059313',
  '#F48A0D',
  '#BCD717',
  '#FF0000',
  '#C71585',
  '#FF4500',
  '#6A5ACD',
  '#8B4513',
  '#000080',
  '#2F4F4F',
  '#CD5C5C',
  '#FFD700',
  '#B0C4DE',
  '#4169E1',
  '#696969',
  '#808000',
  '#FFDAB9',
  '#DEB887',
];
